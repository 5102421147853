// import '../../../public/assets/css/Styles.css'
import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PostApi } from '../services/CommonService2.js'
import { ToastContainer, toast } from 'react-toastify';
import LoginModal from './LoginModal.js';
import noImage from './noImage.jpg'
function State() {
  var [state, setState] = useState([]);
  var [rowModal, setRowModal] = useState(true);
  var [loginModal, setLoginModal] = useState(false);
  var [modalUp, setModalUp] = useState(false);

  useEffect(() => {
    var tntId = JSON.parse(localStorage.getItem('tID'))
    const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))

    if(userdata == null || userdata?.tenant?.tenantid != "ffe21ec9"){
    getData()
    }
  }, [loginModal])
  const getData = async () => {
    var reqparams2 = ''
    var tntId = JSON.parse(localStorage.getItem('tID'))
    const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))
    
    if (userdata == null) {
      reqparams2 = {
        pageno: '-1',
        query: { status: 'Active' }
      }
    }

    else if (userdata?.user.subapplicationid ) {
      reqparams2 = {
        pageno: '-1',
        query: {
          status: 'Active', _id: userdata.user?.stateid,
          area: {
            $elemMatch: { "value": userdata?.user?.subapplicationid }
          }
        },
      }
    }
    else {
      reqparams2 = {
        pageno: '-1',
        query: { status: 'Active', _id: userdata?.user?.stateid },
      }
    }
    
    

    const dataResponse = await PostApi(reqparams2, 'STATES');
    // console.log("states reponse",dataResponse.data);
    var newApiStateData = dataResponse?.data?.map((ele, index) => {
      return {
        ...ele,
        toggleState: index < 12 ? true : false
      }
    })
    setState(newApiStateData)
  }
  const toggleRows = () => {
    setRowModal(!rowModal);

    var finalArray = state.map((ele, index) => {
      if (index < 12) {
        return {
          ...ele,
        }
      }
      else {
        return {
          ...ele,
          toggleState: !ele.toggleState
        }
      }
    }
    )
    setState(finalArray)
  };

  const loginValidate = async (e) => {
    var tntId = JSON.parse(localStorage.getItem('tID'))
    const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))
    if (userdata == null) {
      setModalUp(true)
      e.preventDefault()

      toast.info('login to check course details', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
  const openModal = () => {
    setModalUp(false);
    setLoginModal(!loginModal);
  }
  const login = () => {
    // Dont remove this function it was login purpose
  }

  const logIn = () => {

  }

  return (<>
    {/* Universities list block Start  */}
    {state && state?.length>0 && <section className="plans-main-block bg-blue">
      {/* {console.log('states========',state)} */}
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <h3 className="main-title">B Tech Academic Subjects from list of universities </h3>
            <p className="description px-xl-5">In the yearly programs, Post Graduates are guided what to learn on yearly basis along with the academics. The courses are designed for both Programming and Non-programming streams based on the candidate’s capabilities and areas of interest. All the courses are well explained with real-time projects & examples.</p>
          </div>
        </div>

        <div>
          <div className="container-lg">
            <div className="row" style={state.length < 2 ? { marginLeft: '45%' } : {}}>
              {state?.map((ele, ind) => {
                if (ele.toggleState) {

                  let imageid = 'https://visualpathedu.com/static/media/4th-year.15b862d606512e3257c0.jpg';
                  if (ele?.image) {
                    imageid = ele.image;
                  } else if (ele?.image_url) {
                    imageid = ele.image_url;
                  }
                  else {
                    imageid = ele.imageurl ? ele.imageurl : "";
                  }
                  return (
                    <div className="col-6 col-xl-2 col-lg-3 mb-4" key={ind}>
                      <Link to={{ pathname: '/subjects', state: { data: ele.id } }} onClick={loginValidate}><div className="state-block">
                        <div className="img-icon">
                          <img src={ele && (ele.image ? ele.image : noImage)} style={{ height: '100%', width: "100%" }} />
                        </div><span className='text-dark'>{ele.name}</span>
                      </div></Link>
                    </div>
                  )
                }
                else {
                  return null;
                }

              })}
            </div>
          </div>

          {state.length > 12 && <div className="row">
            <div className="col-12 btn-circle-container" style={{ textAlign: 'center' }} >
              <button type="button" className="btn btn-outline-success" id="downArrowBtn" onClick={toggleRows}>
                {rowModal ? <b className="me-1">More</b> : <b className="me-1">Go back</b>} <b className={`fa ${rowModal ? 'fa-chevron-down' : 'fa-chevron-up'}`}></b>
                {/* ..more states */}
              </button>
            </div>
          </div>}
        </div>
      </div>
    </section>}
    {modalUp && <LoginModal onClose={openModal} login={logIn}></LoginModal>}


  </>)
}
export default State;