import React, { Component, useEffect, useState } from 'react';
import HeaderTwo from './components/HeaderTwo';
import HeroImage from './components/HeroImage';
import ServiceBox from './components/ServiceBox';
import AboutUsTwo from './components/AboutUsTwo';
import CourseSlider from './components/CourseSlider';
import NumberCounter from './components/NumberCounter';
import FreeCourse from './components/FreeCourse';
import TeamSlider from './components/TeamSlider';
import TestimonialSlider from './components/TestimonialSlider';
import HomeBlog from './components/HomeBlog';
import ImageGallery from './components/ImageGallery';
import FooterTwo from './components/FooterTwo';
import States from './components/States';
import GuidanceHome from './pages/courses/components/GuidanceHome';
import BridgeCourse from './components/BridgeCourse';
import FundamentalsAssesments from './components/FundamentalsAssesments';
import Header3 from './home3components/Header3';
import LoginModal from './components/LoginModal';


function HomeTwo() {
    const [tenant, setTenant] = useState(false)
    const [tenantId,setTenantId] = useState('')
    const [modalOpen, setModalOpen] = useState(false)
    const [password, setPassword] = useState("")
    useEffect(() => {
        var tntId = JSON.parse(localStorage.getItem('tID'))
        console.log("tntId", tntId);
        setTenantId(tntId)
        if (tntId == "7775249d") {
            setTenant(true)
        }
        abc()
    }, [])

    const abc = async () => {
        let psName = localStorage.getItem('PW')

        if (psName == "changepassword") {
          setPassword("changepassword")
        }
      }
      setTimeout(async () => {
        await localStorage.removeItem('PW')
        abc()
      }, 4000)
    
      useEffect(() => {
        if (password == "changepassword") {
          setModalOpen(true)
        }
    
      }, [password])
    
      const closemodal = () => {
        setModalOpen(false)
        setPassword("")
    
      }
      const login = async () => {
    
      }
    
    return (
        <div className="main-wrapper">
            {/* Header 2 */}
            {tenantId =="ffe21ec9" ? <Header3 /> : <HeaderTwo />}
            {/* <HeaderTwo /> */}

            {/* Hero Image */}
            <HeroImage />

            {/* Service Box */}
            <ServiceBox />
            {tenant && <FundamentalsAssesments />}

            {tenant && <BridgeCourse />}


            {/* About Us 2 */}
            <AboutUsTwo />

            <States />



            <GuidanceHome />


            {/* Course Slider */}
            {/* <CourseSlider /> */}

            {/* Counter Area */}
            {/* <NumberCounter /> */}

            {/* Free Course Area */}
            {/* <FreeCourse /> */}

            {/* Team Slider */}
            {/* <TeamSlider /> */}

            {/* Testimonial Slider */}
            {/* <TestimonialSlider /> */}

            {/* Blog Area */}
            {/* <HomeBlog /> */}

            {/* Image Gallery Area */}
            {/* <ImageGallery /> */}

            {/* Footer 2 */}
            <FooterTwo />

            {modalOpen && <LoginModal onClose={closemodal} login={login} ></LoginModal>}
        </div>
    )

}
export default HomeTwo
