import { loadStripe } from '@stripe/stripe-js';

import './homethree.css'
import { Elements } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { PostApi, GetApi } from './services/CommonService2';
import Header3 from './home3components/Header3'
import Banner3 from './home3components/Banner3'
import HomePrograms3 from './home3components/HomePrograms3'
import HomeCourses3 from './home3components/HomeCourses3'
import Footer3 from './home3components/Footer3'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min.js';
import { toast } from 'react-toastify';
import LoginModal from './components/LoginModal';
import StripeForm from './components/StripeForm';
function HomeThree() {
    const [sliders, setSliders] = useState([]);
    const [constituencySlider, setConstituencySlider] = useState("");

    const [tenantData, settenantData] = useState([]);
    const [issubscribed, setIssubscribed] = useState(false);
    const [userToken, setUserToken] = useState(false);
    const [modalValue, setModalValue] = useState(false);
    const [modalValue2, setModalValue2] = useState(false);
    const [userdata, setUserData] = useState(null);
    const [stripePromise, setStripePromise] = useState(null);
    const [introContent, setIntroContent] = useState([])
    // const [confirmation, setConformation] = useState(false)
    const [openModal, setOpenModal] = useState(true)
    const [modalUp, setModalUp] = useState(false);
    const [subscribed, setSubscribed] = useState(false)
    const [secondBanner, setSecondBanner] = useState([])
    const [playModal, setPlayModal] = useState(false);
    const [tcsData, setTcsData] = useState([])
    const history = useHistory()
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const publishableKey = 'pk_test_51ODj74SJbFT27gwoONAnMJ0endmdF071eX4FJ248gS37R5DAlcqrJ3YLiPxg053askOwRi7Pc4cl74mpyjXanfdZ00z0PVoKGR';

    const [tenant, setTenant] = useState(false)
    const [tenantId,setTenantId] = useState('')
    const [modalOpen, setModalOpen] = useState(false)
    const [password, setPassword] = useState("")
    useEffect(() => {
        abc()
    }, [])

    const abc = async () => {
        let psName = localStorage.getItem('PW')

        if (psName == "changepassword") {
          setPassword("changepassword")
        }
      }
      setTimeout(async () => {
        await localStorage.removeItem('PW')
        abc()
      }, 4000)
    
      useEffect(() => {
        if (password == "changepassword") {
          setModalUp(true)
        }
    
      }, [password])
    

    useEffect(() => {


        loaddata();
        setStripePromise(loadStripe(publishableKey))
        checkSubscribe()
        getSliders();
        getTcsProgram()
    }, [])

    const checkSubscribe = async () => {
        var tntId = JSON.parse(localStorage.getItem('tID'))
        const sessiondetails = localStorage.getItem(`userdata${tntId}`);
        if (sessiondetails != null) {
            const userdata = JSON.parse(sessiondetails);
            const reqdata = {
                userid: userdata?.id,
                ptype: 'USERSUBSCRIPTION',
                pageno: '-1',
                query: { userid: userdata?.id }
            }

            const userSubsResp = await PostApi(reqdata, 'USERSUBSCRIPTION');
            // console.log(userSubsResp, "userSubsResp check user subcruption")
            if (userSubsResp?.data?.length > 0) {
                setSubscribed(true)
            } else {
                setSubscribed(false)

            }
        }
    }

    const getSliders = async () => {
        const reqparam1 = {
            pageno: '-1',
            query: {}
        }

        const tenantresp = await PostApi(reqparam1, 'TENANT');
        console.log(tenantresp, "tenantresp")
        settenantData(tenantresp?.data[0])
        // console.log(tenantresp.data[0].type, "tenantresp tenantresp")
        const reqparam = {
            pageno: '-1',
            query: { status: 'Active' }
        }

        // const ResponseData = await PostApi(reqparam, action);
        const intoContentData = await GetApi(reqparam, "INTRO");
        setIntroContent(intoContentData.data)
        setConstituencySlider(intoContentData.data)
        // console.log(userdata, "userdata")

        const reqparamWeb = {
            pageno: '-1',
            query: { status: 'Active', subapplicationid: "" },
            bannerweb: 'Y',

        }


        const ResponseData2 = await PostApi(reqparamWeb, "HOMEBANNER");
        console.log("banner changed", ResponseData2);
        setSliders(ResponseData2?.data);

    }



    const loaddata = async () => {

        const reqparam = {
            pageno: '-1',
            query: {}
        }

        const tenantresp = await PostApi(reqparam, 'TENANT');
        // console.log("tenant", tenantresp.data);
        // settenantData(tenantresp.data[0]);
        var tntId = JSON.parse(localStorage.getItem('tID'))
        const sessiondetails = localStorage.getItem(`userdata${tntId}`);

        if (sessiondetails != null) {
            const localUserdata = JSON.parse(sessiondetails);
            console.log(localUserdata, "check user id from the hero image latest")
            setUserData(localUserdata);
            setUserToken(true);
            const subscriptionreqdata = {
                userid: localUserdata.id,
                ptype: 'USERSUBSCRIPTION',
                pageno: '1',
                // query: { userid: localUserdata.id }
            }
            const userSubsResp = await PostApi(subscriptionreqdata, 'USERSUBSCRIPTION');
            // if (userSubsResp.data.docs.length > 0) {
            //   setIssubscribed(true);
            // }
        }
    }


    const confirmsubscription = async (amount) => {

        // await initializePaymentSheet();
        setOpenModal(true)
        setModalValue2(true)
        setModalValue(false)

    }

    const getTcsProgram = async () => {
        const reqparams = {
            pageno: -1,
            query: { status: 'Active', _id: '660b92c3fa95f0377f1fe5f1' }

        }
        const response = await PostApi(reqparams, 'ITPROGRAMS');
        setTcsData(response?.data[0])
    }

    const closemodal = () => {
        setOpenModal(false)
        // setModalShow(true)

    }
    const hashChangeEvent2 = async () => {
        const reqDataSubcribe = {
            userid: userdata.id,
            tenant: userdata.tenant,
        }
        const userSubsResp = await PostApi(reqDataSubcribe, 'USERSUBSCRIPTION');

        try {
            const reqParams = { name: userdata.username, amount: Number(tenantData?.offeramount), userid: userdata.id, courseid: "0", couponcode: "0" };

            const reqdataCoupon = await GetApi(reqParams, "STRIPEKEY");

            if (reqdataCoupon.message == 'SUCCESS') {
                if (tenantData?.offeramount) {
                    const reqdata = {
                        amount: Number(reqdataCoupon.amount),
                        userid: userdata.id
                    }
                    const orderResp = await PostApi(reqdata, 'RAZORPAYORDERID');

                    const options = {
                        name: 'visualpath',
                        image: 'https://asseshub.com/resume1/assets/images/adaptive-icon.png',
                        description: 'Program purchase',
                        order_id: orderResp.id,
                        key: "rzp_live_VMuQP30hpyTin3",
                        prefill: {
                            email: userdata.email,
                            contact: userdata.username,
                            // name: userdata.localUserdata,
                        },
                        theme: { color: '#a29bfe' },
                        handler: async (transactions) => {
                            // console.log(transactions, "transaction");


                            const resdataSubcribe = {
                                userid: userdata.id,
                                transaction: transactions,
                                orderID: orderResp.id,
                                programid: "0",

                            }
                            const respSignatureData = await PostApi(resdataSubcribe, 'VERIFYPAYMENTS');
                            // console.log(respSignatureData.message, "respSignatureData");
                            setSubscribed(true)
                            //  const resdata = {
                            //   userid: userdata.id,
                            //   transaction: transactions,
                            //   orderID: orderResp.id,
                            //   programid: "0"
                            //  }
                            //  console.log(resdata, "resdata home image")
                            //  const respSignatureData = await PostApi(resdata, 'VERIFYPAYMENTS');
                            //  console.log(respSignatureData, "respSignatureData");


                        }
                    };
                    const razor = new window.Razorpay(options);

                    razor.open()
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    //login validate

    const loginValidate = (data) => {
        var tntId = JSON.parse(localStorage.getItem('tID'))
        const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))
        console.log(userdata, "check user id from the hero image latest")
        if (userdata == null) {
            setModalUp(true)
            // console.log("inside");
            // e.preventDefault()

            toast.info('login to check course details', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        else {
            setModalValue(true);
            setIssubscribed(true)
        }
    }

    const partnerPage = async () => {
        var tntId = JSON.parse(localStorage.getItem('tID'))
        const userdata = JSON.parse(localStorage.getItem(`userdata${tntId}`))

        if (userdata == null) {
            setModalUp(true)

            toast.info('login required', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        else {
            history.push('/partner-onboard')
        }

    }

    const openModalfn = () => {
        setModalUp(false)
        setPassword("")
    }

    const logIn = () => {

    }


    const playStyleMargin = {
        marginTop: tenantData?.subscriptionallowed == "Yes" && "20px"
    }

    return (
        <>
            <div >
                <Header3 />
                <Banner3 />



                {tenantData?.subscriptionallowed == "Yes" &&
                    (!subscribed && <div className='w-100 rounded  bg-primary ' style={{ height: "auto", cursor: 'pointer' }} onClick={loginValidate}>


                        <p className='text-center text-light font-bold subscription-tag'>Subscribe to entire platform at just  <span style={{ color: "#fff" }}>
                            <s style={{ color: "#fff", fontWeight: "bold" }}>
                                {tenantData?.subscriptionamount > 0 &&
                                    <span>
                                        Rs.{tenantData?.subscriptionamount}
                                    </span>}
                            </s>
                        </span>{" "}
                            Rs.{tenantData?.offeramount} For Life time  
                            &nbsp;&nbsp;
                            <i
                               
                                className="fa fa-cart-plus"
                            ></i></p>

                        {/* <span className='text-white'
              style={StyleSheet.overTitle}>
              Subscribe to entire platform at just{" "}
            
            </span> */}
                    </div>)}



            </div>

            {modalUp && <LoginModal onClose={openModalfn} login={logIn}></LoginModal>}
            {issubscribed ? <Modal show={issubscribed} style={{ textAlign: 'center' }}>
                <Modal.Header>
                    <Modal.Title><h2>Confirmation !!</h2>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6>your are about to do a Payment of Rs.{tenantData?.offeramount}/- To subscribe entire platform</h6>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setIssubscribed(false) }}>
                        CANCEL
                    </Button>

                    <Button variant="primary" onClick={() => hashChangeEvent2()}>
                        Proceed to pay
                    </Button>
                </Modal.Footer>
            </Modal> :
                <Modal >
                    <Modal.Header closeButton>
                        <Modal.Title>Platform Subscription</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>You have already subscribed to the platform</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { setModalValue(false) }}>
                            ok
                        </Button>
                    </Modal.Footer>
                </Modal>}

            {stripePromise && modalValue2 &&
                (
                    <Modal size="l" show={openModal} >
                        <div className="" role="document">
                            <div className="">
                                <form >
                                    <div className="modal-header">

                                        <button type="button" className="btn-close" data-dismiss="modal" onClick={closemodal}></button>
                                    </div>
                                    <div className="modal-body">
                                        <Elements stripe={stripePromise}>

                                            <StripeForm tenantData={tenantData}></StripeForm>

                                        </Elements>
                                    </div>
                                </form>

                            </div>
                        </div>




                    </Modal>)
            }
            <HomePrograms3 />
            <HomeCourses3 className="my-5" />
            <Footer3 />
        </>


    )
}

export default HomeThree